import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import { ICompanyDTO, IFileDTO } from "@/models/Company";
import { IResponse } from "@/services/axios/entities";
import { getEnv } from "@/utils/storage";
import { ICreateTenantResponse } from "@/models/Tenant";

const COMPANIES = "Tenants";

export class CompanyManagementAPI extends HttpClient {
  constructor(private env = getEnv()) {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getDataCompany = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${COMPANIES}`, {})
      .catch(catchAxiosError);

    return response;
  };

  public createDataCompany = async (data: ICompanyDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${COMPANIES}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updateDataCompany = async (data: ICompanyDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${COMPANIES}/${data.Id}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public pushFile = async (data: IFileDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${COMPANIES}/${data.Id}/files`, data.File, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .catch(catchAxiosError);

    return response;
  };

  public getFile = async (
    params: ICreateTenantResponse
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${COMPANIES}/${params.TenantId}/files`)
      .catch(catchAxiosError);

    return response;
  };

  public createDataRegisterCompany = async (
    data: ICompanyDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${COMPANIES}/${data.TenantId}/register`, data)
      .catch(catchAxiosError);

    return response;
  };

  public pushFileRegisterCompany = async (
    data: IFileDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${COMPANIES}/${data.TenantId}/${data.Id}/files`, data.File, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .catch(catchAxiosError);

    return response;
  };
}
